import React, { memo } from 'react';
import { SLIDE_CONFIG_LIST_LOGO_FOOTER } from './constants';
import Slider from 'react-slick';
import { I_Layout, I_Translations } from '@containers/App/types';

interface Props {
  layout?: I_Layout;
  trans?: I_Translations;
}

const Footer = memo(({ layout, trans }: Props) => {
  return (
    <footer className="--footer">
      <div className="footer-left">
        <div className="--listLogo">
          <Slider {...SLIDE_CONFIG_LIST_LOGO_FOOTER}>
            {layout?.footer?.footerList?.map((item, i) => (
              <div className="--item" key={i}>
                <img
                  src={
                    item?.logo?.thumbnail_640_jpg ||
                    item?.logo?.thumbnail_720_jpg ||
                    item?.logo?.default
                  }
                  alt=""
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="footer-right">
        <a href="" className="--developers link">
          {trans ? trans['fe.footer.powered'] : ''}
        </a>
        <div className="--view">
          <i className="fa-solid fa-eye"></i>{' '}
          <span>{layout?.footer?.view_count}</span>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
